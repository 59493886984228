<template>
  <!-- 登录页 -->
  <v-app>
    <v-main class="align-center">
      <v-container>
        <v-card max-width="520px" class="mx-auto px-16 align-center" elevation="10">
          <v-card-title class="justify-center pb-0">
            <v-img src="../assets/i/favicon.png" max-height="45" max-width="45"></v-img>
            <p class="ml-3 mt-2 headline">遥感工具平台</p>
          </v-card-title>
          <v-card-subtitle class="d-flex justify-center">欢迎登录</v-card-subtitle>
          <v-divider></v-divider>

          <v-card-text>
            <!-- 登录表单 -->
            <v-form ref="form">
              <v-row no-gutters class="">
                <v-col cols="12" sm="2" class="mt-2">用户名</v-col>
                <v-col cols="12" sm="10">
                  <v-text-field solo dense v-model="form.username" autofocus :rules="[v => !!v || '用户名不能为空']" @keyup.enter="login()">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="2" class="mt-2">密码</v-col>
                <v-col cols="12" sm="10">
                  <v-text-field solo dense type="password" v-model="form.password" :rules="[v => !!v || '密码不能为空']"  @keyup.enter="login()">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" class="">
                <v-col cols="12" sm="10" offset="2">
                  <p class="caption text-end">
                    还没有账号?<v-btn text color="primary" @click="toRegisterPage()">前往注册</v-btn>
                  </p>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-btn color="primary" @click="login()">登录</v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- tips -->
        <v-snackbar v-model="snackbar" :color="color" :timeout="2000" :top="true">
          {{ text }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import axios from "axios";
import qs from "qs"; //处理提交的表单数据
import commonCfg from "../config/common";
let commonUrl = commonCfg.commonUrl;
let version = commonCfg.urlVersion;

export default {
  data () {
    return {
      // tips
      snackbar: false,
      color: "error",
      text: "",

      form: {
        username: localStorage.username || "",
        password: "",
        grant_type: "password"
      }
    };
  },
  watch: {
    form: {
      deep: true,
      handler (val) {
        val.username = val.username.toLowerCase();
      }
    }
  },
  created () { },
  methods: {
    toRegisterPage () {
      this.$router.push("/register");
    },
    async login () {
      if (!this.$refs.form.validate()) {
        // axiosTool
        //   .login('token', this.form)
        //   //在使用另一个函数调用它时从promise中获取值
        //   .then(res => {
        //     if (res) {
        //       document.cookie = 'token=' + res.access_token + '; path=/' //token放进cookie里
        //       localStorage.token = res.access_token
        //       localStorage.refresh_token = res.refresh_token
        //       localStorage.username = this.form.username
        //       this.$router.push('/stationList')
        //     } else {
        //       this.text = '用户名或密码错误'
        //       this.snackbar = true
        //     }
        //   })
        return;
      }
      axios
        .post(`${commonUrl}token${version}`, qs.stringify(this.form))
        .then(res => {
          console.log(res);
          if (res.data) {
            document.cookie = "token=" + res.data.access_token + "; path=/"; //token放进cookie里
            localStorage.token = res.data.access_token;
            localStorage.refresh_token = res.data.refresh_token;
            localStorage.username = this.form.username;
            //根据用户名判断角色，确定要跳转的页面
            this.$router.push("/main"); //customerMain projectMain
          } else {
            this.text = "用户名或密码错误";
            this.snackbar = true;
          }
        })
        .catch(e => {
          console.log(e);
          this.text = "用户名或密码错误";
          this.snackbar = true;
        });
    }
  }
};
</script>
<style>
.v-application .error--text {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
</style>
